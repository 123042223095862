<template>
  <div>
    <main class="dgrid-body dgrid-container text--body1" :class="{'mobile':mobile}">
      <div class="flow">
        <h2 class="view-title">{{ $t('help_titolo_pagina') }}</h2>
        <h4>{{ $t('help_descrizione_pagina') }}</h4>

        <div v-for="i in numFaqs" :key="i" class="faq">
          <div class="collapsable-header bordered rounded stack-horizontal" @click="toggleCollapsable" data-align="center">
            <p class="text--body1-medium">{{ $t('help_domanda_' + i) }}</p>
            <base-icon name="arrow-move-down" colorFill="primary-50" class="pushed-right"/>
          </div>
          <div class="collapsable">{{ $t('help_risposta_' + i) }}</div>
        </div>        
      </div>
    
    </main>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Help',
  data() {
    return {
      numFaqs: 3
    }
  },
  computed: {
    ...mapState('interface', ['mobile'])
  },
  methods: {
    toggleCollapsable(ev) {
      let faq = ev.target.closest('.faq');
      let coll = faq.querySelector('.collapsable');

      let isOpen = faq.classList.contains('open');
      if (!isOpen){
        coll.style.maxHeight = coll.scrollHeight + 'px';
        faq.classList.add('open')
        faq.querySelector('.icon').style.transform = 'rotateX(180deg)'
      } else {
        coll.style.maxHeight = 0 + 'px';
        faq.classList.remove('open');
        faq.querySelector('.icon').style.transform = 'rotateX(0deg)'
      }
    },
    onResize() {
      setTimeout( ()=> {
        let faqs = [...document.querySelectorAll('.faq')];
        faqs.forEach(el => {
          if (el.classList.contains('open')) {
            let coll = el.querySelector('.collapsable');
            coll.style.maxHeight = coll.scrollHeight + 'px';
          }
        })
      },100);
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
<style lang="scss">
  #help.view {

    --flow-space: var(--s4);
    color: var(--color-black-text);

    main {
      display: block;
      padding-bottom: var(--s6);
      --dgrid-item-col: 7;
    }
    main:not(.mobile) {
      --padding: calc(var(--dgrid-col-w) + var(--dgrid-gap));
      padding-left: var(--padding);
      padding-right: var(--padding);
      > div {
        max-width: var(--dgrid-item-w);
      }
    }

    .faq {
      --color-border: var(--color-line-light);
      --radius: calc(var(--s0) / 2);

      .collapsable-header {
        cursor: pointer;
      }
      .collapsable-header,
      .collapsable {
        padding: var(--s0) var(--s1);
      }
      .collapsable {
        pointer-events: none;
        transition: all var(--transition-duration) var(--transition-type);
        max-height: 0;
        opacity: 0;
        padding-top: 0px;
      }

      .icon {
        flex-shrink: 0;
      }
    }

    .faq.open .collapsable {
      max-height: 100vh;
      opacity: 1;
      padding-top: var(--s1);
    }
  }

  @media (max-width: 720px) {
    #help.view {
      --flow-space: var(--s3);
    }    
  }
</style>
